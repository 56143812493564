import "./App.css";
import PunishmentList from "./components/PunishmentList";
import Logo from "./images/logo.png";

function App() {
	return (
		<div>
			<div
				className="logo-container"
				style={{ display: "flex", flexDirection: "column" }}
			>
				<img className="logo" src={Logo} alt="" />
				<h1>JackpotMC Punishments</h1>
				<h3>All punishments can be viewed here</h3>
			</div>
			<PunishmentList />
		</div>
	);
}

export default App;
