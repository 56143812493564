import React, { useEffect, useState } from "react";
import "./PunishmentList.css";

const PunishmentList = () => {
	const [searchTerm, setSearchTerm] = useState("");
	const [punishments, setPunishments] = useState([]);

	useEffect(() => {
		fetch("https://bans.jackpotmc.com/backend/api/punishments/")
			.then((response) => response.json())
			.then((response) => {
				setPunishments(response);
			});
	}, []);

	const filteredPunishments = punishments.filter((punishment) =>
		punishment.playerName.toLowerCase().includes(searchTerm.toLowerCase())
	);

	return (
		<div className="punishment-list-container">
			<input
				type="text"
				className="search-bar"
				placeholder="Search by username..."
				value={searchTerm}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<div className="punishment-list">
				{filteredPunishments.map((punishment, index) => (
					<div
						className={`punishment-card ${punishment.punishmentType}`}
						key={index}
					>
						<div className="punishment-row">
							<div className="punishment-item">
								<span className="label">Username:</span> {punishment.playerName}
							</div>
							<div className="punishment-item">
								<span className="label">Reason:</span> {punishment.reason}
							</div>
							<div className="punishment-item">
								<span className="label">Length:</span> {punishment.duration}
							</div>
							<div className="punishment-item">
								<span className="label">Punisher:</span> {punishment.adminName}
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default PunishmentList;
